@import "variables/_variables.scss";
label.required::after {
  content: ' *';
  color: red;
}

td.z-index-3 {
  z-index: 3!important;
}

.agenda-filter-label {
  display: inline-block;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: 5px solid #3788d8;
  border: calc(var(--fc-list-event-dot-width, 10px) / 2) solid var(--fc-event-border-color, #3788d8);
  border-radius: 5px;
  border-radius: calc(var(--fc-list-event-dot-width, 10px) / 2);
}